
.eCommerce-container {
    height: 100%;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
        & > .el-scrollbar__view {
            min-width: 1400px;
        }
    }
}
.top-bar {
    margin: 30px 0 0 44px;
    width: 218px;
    height: 50px;
    background: url("../../../../assets/images/student/customer-info.png") no-repeat;
    .text {
        padding-left: 70px;
        line-height: 50px;
        color: #fff;
        font-size: 20px;
    }
}
.detail-box {
    margin: 30px 80px 0 44px;
    display: flex;
    .detail-item + .detail-item {
        margin-left: 40px;
    }
    .detail-item {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 8px 10px 1px rgba(13, 0, 49, 0.05), 0 8px 25px 4px rgba(13, 0, 49, 0.05);
        .detail-title {
            margin-top: 26px;
            margin-left: 40px;
            position: relative;
            font-size: 18px;
            line-height: 1;
            &:before {
                content: '';
                width: 5px;
                height: 20px;
                background: #2DC079;
                border-radius: 3px;
                position: absolute;
                left: -15px;
                top: -1px;
            }
        }
        .customer-info {
            padding: 10px 42px;
            .info-item {
                display: flex;
                flex-wrap: wrap;
                font-size: 18px;
                margin-top: 40px;
                .item-left {
                    width: 78px;
                    color: #666;
                    text-align-last: justify;
                }
                .item-right {
                    width: 1%;
                    flex: 1;
                    margin-left: 20px;
                    .sex-item {
                        display: inline-block;
                        .iconfont {
                            color: #ccc;
                            font-size: 20px;
                            margin-right: 5px;
                        }
                    }
                    .sex-item + .sex-item {
                        margin-left: 70px;
                    }
                }
            }
        }
        .buy-info {
            padding: 30px 68px 45px;
            .info-line {
                margin-top: 15px;
                border-bottom: 1px solid #eee;
                display: flex;
                justify-content: space-between;
                &:last-child {
                    border-bottom: none;
                }
                .info-item {
                    padding: 0 25px 25px;
                    .item-top {
                        display: flex;
                        align-items: center;
                        color: #666;
                        .iconfont {
                            font-size: 20px;
                            margin-right: 8px;
                        }
                    }
                    .item-content {
                        margin-top: 22px;
                        font-size: 18px;
                        text-align: center;
                        span {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .evaluate-info {
            padding: 10px 42px;
            .info-item {
                margin-top: 40px;
                display: flex;
                align-items: center;
                font-size: 18px;
                .item-left {
                    margin-right: 12px;
                    color: #666;
                }
            }
        }
    }
}
.bottom-btn {
    margin: 120px 0;
    text-align: center;
}
.green {
    color: #2DC079;
}
.red {
    color: #FF0000;
}
.orange {
    color: #FF8A00;
}
.blue {
    color: #2461EF;
}
.rose-red {
    color: #fe3ec9!important;
}
.light-blue {
    color: #74b8ea!important;
}
.gradient-blue {
    background-image: linear-gradient(55deg, #7FC5FF 0%, #499EFF 100%);
    -webkit-background-clip: text;
    color: transparent;
}
.gradient-green {
    background-image: linear-gradient(55deg, #54E39D 0%, #32C67E 100%);
    -webkit-background-clip: text;
    color: transparent;
}
.gradient-red {
    background-image: linear-gradient(55deg, #FF7E69 0%, #F74835 100%);
    -webkit-background-clip: text;
    color: transparent;
}
.gradient-orange {
    background-image: linear-gradient(55deg, #FFCC56 0%, #FDA247 100%);
    -webkit-background-clip: text;
    color: transparent;
}
.gradient-purple {
    background-image: linear-gradient(55deg, #D1B6FB 0%, #8870FA 100%);
    -webkit-background-clip: text;
    color: transparent;
}
